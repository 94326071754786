import { useState, useEffect, useRef } from "react";
import ReactPlayer from "react-player";
import WaveSurfer from "wavesurfer.js";


const TestV1 = () => {
    const [videoSources, setVideoSources] = useState(["https://tazmeen.ai/media/cloud/Ep45/2023-09-1613_30_34/v1.mp4"]);
    const waveSurferRef = useRef<WaveSurfer | null>(null);
    const playerRef = useRef<ReactPlayer | null>(null);

    const waveformRef = useRef(null);
    useEffect(() => {
        if (waveformRef.current) {
            waveSurferRef.current = WaveSurfer.create({
                container: waveformRef.current,
                waveColor: "violet",
                progressColor: "purple",
            });

            waveSurferRef.current.load("https://tazmeen.ai/media/cloud/Ep45/2023-09-1613_30_34/v1.mp4");

            const handleSeek = (progress: number) => {
                if (playerRef.current) {
                    const duration = playerRef.current.getDuration();
                    const newTime = progress * duration;
                    playerRef.current.seekTo(newTime);
                }
            };

            waveSurferRef.current.on("seek" as any, handleSeek);

            return () => {
                if (waveSurferRef.current) {
                    waveSurferRef.current.un("seek" as any, handleSeek);
                    waveSurferRef.current.destroy();
                }
            };
        }
    }, []);

    return (
        <>
            {videoSources.map((url, index) => (
                <div key={index}>
                    <ReactPlayer
                        ref={playerRef}
                        url={url}
                        controls={true}
                        width="800px"
                        height="450px"
                        onPlay={() => waveSurferRef.current && waveSurferRef.current.play()}
                        onPause={() => waveSurferRef.current && waveSurferRef.current.pause()}
                    />
                </div>
            ))}
      
            <div ref={waveformRef}></div>
        </>
    );
};

export default TestV1;
