/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect } from 'react'
import { Link, Outlet, Route, Routes } from 'react-router-dom'
import Login from './components/Login'
import logoImg from '../../assets/img/logo.svg'
// import {Registration} from './components/Registration'
// import {ForgotPassword} from './components/ForgotPassword'



const AuthLayout = () => {
    useEffect(() => {
        document.body.classList.add('bg-body')
        return () => {
            document.body.classList.remove('bg-body')
        }
    }, [])

    return (
        // <div
        //   className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'
        //   style={{
        //     backgroundImage: `url(${toAbsoluteUrl('/media/illustrations/sketchy-1/14.png')})`,
        //   }}
        // >
        //   {/* begin::Content */}
        //   <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20'>
        //     {/* begin::Logo */}
        //     {/* <a href='#' className='mb-12'>
        //       <img alt='Logo' src={toAbsoluteUrl('/media/logos/icon.jpeg')} className='h-45px' />
        //     </a> */}
        //     {/* end::Logo */}
        //     {/* begin::Wrapper */}
        //     <div className='w-lg-500px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto'>
        <div className="public-layout">
            <div className="page-box">
                <div className="app-container page-sign-in">
                    <div className="row">
                        <div className="col-lg-6 col-sm-12 contain-aboutus-login">
                            <div className="form-login-left">
                                <div className=" text-center">
                                    <div className="logo">
                                        <img src={logoImg} alt="logo" width="100%" />
                                    </div>
                                    <Link className="btn btn-primary about-us-button" to="/about">
                                        About Us
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <Outlet />
                    </div>
                </div>
            </div>
        </div>
        //     </div>
        //     {/* end::Wrapper */}
        //   </div>
        //   {/* end::Content */}
        //   {/* begin::Footer */}
        //   {/* <div className='d-flex flex-center flex-column-auto p-10'>
        //     <div className='d-flex align-items-center fw-bold fs-6'>
        //       <a href='#' className='text-muted text-hover-primary px-2'>
        //         About
        //       </a>

        //       <a href='#' className='text-muted text-hover-primary px-2'>
        //         Contact
        //       </a>

        //       <a href='#' className='text-muted text-hover-primary px-2'>
        //         Contact Us
        //       </a>
        //     </div>
        //   </div> */}
        //   {/* end::Footer */}
        // </div>
    )
}

const AuthPage = () => (
    <Routes>
        <Route element={<AuthLayout />}>
            <Route path='login' element={<Login />} />
            {/* <Route path='registration' element={<Registration />} />
      <Route path='forgot-password' element={<ForgotPassword />} /> */}
            <Route index element={<Login />} />
        </Route>
    </Routes>
)

export { AuthPage }
