import { QueryClient, QueryClientProvider } from 'react-query'
import { createRoot } from 'react-dom/client';
import { AppRoutes } from "./routing/AppRoute";
import { setupAxios } from './app/auth/core/AuthHelpers';
import axios from 'axios';
import { AuthProvider } from './app/auth';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/layout/custom.css'
import './assets/css/styles.scss'

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            cacheTime: 36000000,
            keepPreviousData: true,
            refetchOnWindowFocus: false,
        },
    },
})

setupAxios(axios)

const container = document.getElementById('root')
if (container) {
    createRoot(container).render(
        <QueryClientProvider client={queryClient} >
            <AuthProvider>
                <AppRoutes />
            </AuthProvider>

        </QueryClientProvider>
    )
}

