import {
    FC,
    useState,
    useEffect,
    createContext,
    useContext,
    useRef,
    Dispatch,
    SetStateAction,
} from 'react'

import { AuthModel, EpisodeData, UserModel } from './_models'
import * as authHelper from './AuthHelpers'
import { getUserByToken } from './_request'
import Spinner from '../../components/spinner'


type AuthContextProps = {
    auth: AuthModel | undefined,
    //currentTime: string,
    // setCurrentTime: Dispatch<SetStateAction<string>>,
    saveAuth: (auth: AuthModel | undefined) => void
    currentUser: UserModel | undefined
    setCurrentUser: Dispatch<SetStateAction<UserModel | undefined>>
    logout: () => void,
    episodeData: EpisodeData | null;
    setEpisodeData: (episodeData: EpisodeData, episodeAdded: boolean) => void;
}

const initAuthContextPropsState = {
    auth: authHelper.getAuth(),
    //currentTime: "",
    //setCurrentTime: () => { },
    saveAuth: () => { },
    currentUser: undefined,
    setCurrentUser: () => { },
    logout: () => { },
    episodeData: null,
    setEpisodeData: () => { },
}

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState)

const useAuth = () => {
    return useContext(AuthContext)
}

const AuthProvider: FC<any> = ({ children }) => {
    const [auth, setAuth] = useState<AuthModel | undefined>(authHelper.getAuth())
    const [currentUser, setCurrentUser] = useState<UserModel | undefined>()

    const [episodeData, _setEpisodeData] = useState<EpisodeData | null>(() => {
        const episodeDetailsData = localStorage.getItem("episodeData");
        return episodeDetailsData ? JSON.parse(episodeDetailsData) : null;
    });

    const updateEpisodeData = (newEpisodeData: EpisodeData, episodeAdded: boolean) => {
        if (!episodeAdded) {
            const episodeDetailsData = localStorage.getItem("episodeData");
            const currentEpisodeData = episodeDetailsData ? JSON.parse(episodeDetailsData) : null;

            if (currentEpisodeData && currentEpisodeData.project === newEpisodeData.project) {
                return currentEpisodeData;
            } else {
                _setEpisodeData(newEpisodeData);
            }
        } else {
            localStorage.removeItem("episodeData");
        }
    };
    const saveAuth = (auth: AuthModel | undefined) => {
        setAuth(auth)
        if (auth) {
            authHelper.setAuth(auth)
        } else {
            authHelper.removeAuth()
        }
    }

    const logout = () => {
        saveAuth(undefined)
        setCurrentUser(undefined)
    }

    return (
        <AuthContext.Provider value={{ auth, saveAuth, currentUser, setCurrentUser, logout, setEpisodeData: updateEpisodeData, episodeData }}>
            {children}
        </AuthContext.Provider>
    )
}

const AuthInit: FC<any> = ({ children }) => {
    const { auth, logout, setCurrentUser } = useAuth()
    const didRequest = useRef(false)
    const [showSplashScreen, setShowSplashScreen] = useState(true)

    // We should request user by authToken (IN OUR EXAMPLE IT'S API_TOKEN) before rendering the application
    useEffect(() => {

        const requestUser = async (apiToken: string) => {
            try {
                if (!didRequest.current) {
                    const res = (await getUserByToken(apiToken))?.data
                    if (res) {
                       
                        setCurrentUser(res?.user)
                    }
                }
            } catch (error) {
                console.error(error)
                if (!didRequest.current) {
                    logout()
                }
            } finally {

                setShowSplashScreen(false)
            }

            return () => (didRequest.current = true)
        }

        if (auth && auth.api_token) {
            requestUser(auth.api_token)
        } else {
            logout()
            setShowSplashScreen(false)
        }
        // eslint-disable-next-line
    }, [])

    return showSplashScreen ? <Spinner /> : <>{children}</>
}

export { AuthProvider, AuthInit, useAuth }
