import { Link } from "react-router-dom";
import nabeel from '../../assets/img/3.png'
import kenan from '../../assets/img/2.png'
import asadiah from '../../assets/img/1.png'

import saleem from '../../assets/img/8.png'
import bassam from '../../assets/img/9.png'


import waseem from '../../assets/img/7.png'
import samah from '../../assets/img/10.png'

import mhm from '../../assets/img/5.png'
import mamoun from '../../assets/img/6.png'
import redwan from '../../assets/img/11.png'

const About = () => {
    return (
        <>
            <div className="  section title-aboutus">
                <div className="row">
                    <div className="col-lg-9 col-sm-12">
                        <div className=" contain-aboutus-first">
                            <h3>About us</h3>
                            <p>
                                Tazmeen Company was established as a company specialized in the production of software and computer systems. Our company began
                                working on developing our first product, "Tazmeen System", since 2006. It is an innovative computer system that assists in
                                managing and organizing the dubbing process to and from any language in the world. This system has been used by a group of
                                companies working in the field of dubbing for more than thirty thousand hours of various works (cinematic films, television
                                series, animations,documentaries). With the launch of the Artificial Intelligence revolution in 2023, our company began working
                                on developing the Tazmeen system to become more efficient in assisting in the management of dubbing operations through the new
                                product “Tazmeen AI”.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-sm-12">
                        <div className="contain-right">
                            <Link style={{ color: "white", textDecoration: "none" }} to="/">
                                Back <i className="fa fa-arrow-right"></i>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="contain-aboutus mt-5">
                <h5 className="text-center name-aboutus">Co-Founders</h5>
                <div className="row founders mt-4">
                    <div className="col-4">
                        <img src={nabeel} alt="user" width="65%" />

                        <div className="name-aboutus">
                            <span>Nabeel Al Dakkak</span>
                        </div>
                    </div>
                    <div className="col-4">
                        <img src={kenan} alt="user" width="65%" />
                        <div className="name-aboutus">
                            <span>Kenan Al Oulabi</span>
                        </div>
                    </div>
                    <div className="col-4">
                        <img src={asadiah} alt="user" width="65%" />
                        <div className="name-aboutus">
                            <span>Mohammed Asadiah</span>
                        </div>
                    </div>
                </div>
                <hr className="mt-5" />
            </div>

            <div className="contain-aboutus ">
                <div className="text-center ">
                    {" "}
                    <img src="../img/logo.svg" width="45%" alt="" />
                </div>
                <div className="row section-3 mt-4">
                    <div className="col-6">
                        <img src={nabeel} alt="user" width="45%" />

                        <div className="name-aboutus">
                            <span>Nabeel Al Dakkak</span>
                        </div>
                        <div>
                            <p>Product Owner</p>
                        </div>
                    </div>
                    <div className="col-6">
                        <img src={kenan} alt="user" width="45%" />
                        <div className="name-aboutus">
                            <span>Kenan Al Oulabi</span>
                        </div>
                        <div>
                            <p>Senior Software Developer System Engineer</p>
                        </div>
                    </div>
                </div>
                <hr className="mt-5" />
            </div>
            <div className="contain-aboutus ">
                <div className="text-center ">
                    <img src="../img/logo.svg" width="45%" alt="" />
                </div>
                <div className="row founders mt-4">
                    <div className="col-4">
                        <img src={nabeel} alt="user" width="65%" />

                        <div className="name-aboutus">
                            <span>Nabeel Al Dakkak</span>
                        </div>
                        <div>
                            <p>Product Owner</p>
                        </div>
                    </div>
                    <div className="col-4">
                        <img src={saleem} alt="user" width="65%" />
                        <div className="name-aboutus">
                            <span>Saleem Al Dakkak</span>
                        </div>
                        <div>
                            <p>R & D</p>
                        </div>
                    </div>
                    <div className="col-4">
                        <img src={bassam} alt="user" width="65%" />
                        <div className="name-aboutus">
                            <span>Bassam Sulaiman</span>
                        </div>
                        <div>
                            <p>Scrum System</p>
                        </div>
                    </div>
                </div>
                <div className="row section-3 mt-4">
                    <div className="col-6">
                        <img src={waseem} alt="user" width="45%" />

                        <div className="name-aboutus">
                            <span>Waseem Al Dakkak</span>
                        </div>
                        <div>
                            <p>UI \ UX Designer</p>
                        </div>
                    </div>
                    <div className="col-6">
                        <img src={samah} alt="user" width="45%" />
                        <div className="name-aboutus">
                            <span>Sameh Samara</span>
                        </div>
                        <div>
                            <p>Senior Web Developer</p>
                        </div>
                    </div>
                </div>
                <div className="row founders mt-4">
                    <div className="col-4">
                        <img src={mamoun} alt="user" width="65%" />

                        <div className="name-aboutus">
                            <span>Mamoun Ani</span>
                        </div>
                        <div>
                            <p>Stakeholders</p>
                        </div>
                    </div>
                    <div className="col-4">
                        <img src={mhm} alt="user" width="65%" />
                        <div className="name-aboutus">
                            <span>Mohammad Al Dakkak</span>
                        </div>
                        <div>
                            <p>Stakeholders</p>
                        </div>
                    </div>
                    <div className="col-4">
                        <img src={redwan} alt="user" width="65%" />
                        <div className="name-aboutus">
                            <span>Radwan Al Nouri</span>
                        </div>
                        <div>
                            <p>Stakeholders</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default About;
