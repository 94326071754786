import { lazy, FC, Suspense } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
// import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import MasterLayout from "../app/layout/MasterLayout";
import Spinner from "../app/components/spinner";
import { useAuth } from "../app/auth";

const PrivateRoutes = () => {
  const CompanyPage = lazy(() => import("../features/company/index"));
  const ProjectPage = lazy(() => import("../features/project"));
  const ProjectCategoryPage = lazy(
    () => import("../features/project-category/ProjectCategoryPage")
  );
  const EpisodeLinePage = lazy(() => import("../features/episode/lines"));
  const EpisodeDetailsPage = lazy(() => import("../features/episode/details"));
  const ControlPanelPage = lazy(() => import("../features/controlPanel/index"));
  //const CompanyDetailsPage = lazy(() => import('../features/company/details'))
  const NotFoundPage = lazy(() => import("../app/errors/not-found/NotFound"));
  const { currentUser } = useAuth();

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* <Route path='*' element={<Navigate to='/' />} /> */}
        <Route path="notfound" element={<NotFoundPage />} />
        <Route
          path="*"
          element={
            <Navigate
              to={currentUser?.type === "TzAd" ? "/companies" : "/projects"}
            />
          }
        />
        {currentUser?.type == "TzAd" && (
          <Route
            path="/companies/*"
            element={
              <SuspensedView>
                <CompanyPage />
              </SuspensedView>
            }
          />
        )}
        {/* //    <Route path="/companyDetails/:id" element={<SuspensedView><CompanyDetailsPage /></SuspensedView>} /> */}
        {currentUser?.type == "ClAd" && (
          <Route
            path="/projects/*"
            element={
              <SuspensedView>
                <ProjectPage />
              </SuspensedView>
            }
          />
        )}
        {/* {currentUser?.type == 'ClAd' && <Route path="/projects-category/*" element={<SuspensedView><ProjectCategoryPage /></SuspensedView>} />} */}

        <Route
          path="/detailsEpisode/:id/:categoryId"
          element={
            <SuspensedView>
              <EpisodeDetailsPage />
            </SuspensedView>
          }
        />
        <Route
          path="/episodeLines/:id/:categoryId"
          element={
            <SuspensedView>
              <EpisodeLinePage />
            </SuspensedView>
          }
        />
        {currentUser?.type == "TzAd" && (
          <Route
            path="/control-panel/*"
            element={
              <SuspensedView>
                <ControlPanelPage />
              </SuspensedView>
            }
          />
        )}

        {/* Lazy Modules */}

        {/* Page Not Found */}
        {/* <Route path='*' element={<Navigate to='/' />} /> */}
      </Route>
    </Routes>
  );
};

const SuspensedView: FC<any> = ({ children }) => {
  return <Suspense fallback={<Spinner />}>{children}</Suspense>;
};

export { PrivateRoutes };
