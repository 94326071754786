import { FC, lazy } from 'react'
import { Routes, Route, BrowserRouter, Navigate } from 'react-router-dom'
import { PrivateRoutes } from './PrivateRoutes'
import About from '../features/about/About'
// import { useUserContext } from '../app/context/UserContext'
import TestV1 from '../features/testv/TestV1'
import App from '../App'
import { AuthPage } from '../app/auth/AuthPage'
import { useAuth } from '../app/auth'
import NotFound from '../app/errors/not-found/NotFound'

const { PUBLIC_URL } = process.env
const AppRoutes: FC = () => {
  const NotFoundPage = lazy(() => import('../app/errors/not-found/NotFound'))
  const isAuthenticated = localStorage.getItem("a") !== null;
  const { currentUser } = useAuth();

  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <Routes>
        <Route element={<App />}>
 
          {/* <Route path='auth/*' element={<AuthPage />} /> */}
          {/* <Route path='logout' element={<Logout />} /> */}
          <Route path="/about" element={<About />} />
          <Route path='/v1' element={<TestV1 />} />
          {/* <Route path='/auth' element={<Login />} /> */}
          {currentUser?.username ? (
            <>
              <Route path='/*' element={<PrivateRoutes />} />

            </>
          ) : (
            <>

              <Route path='auth/*' element={<AuthPage />} />
              <Route path='*' element={<Navigate to='/auth' />} />
            </>
          )}
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export { AppRoutes }
