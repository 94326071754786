import React from 'react';

const LogoutIcon = () => {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.2327 16.7018H5.95188C4.87521 16.7514 3.82233 16.3749 3.02125 15.6539C2.22017 14.9328 1.73538 13.9252 1.67188 12.8493V5.14592C1.73538 4.06998 2.22017 3.06238 3.02125 2.34131C3.82233 1.62024 4.87521 1.24377 5.95188 1.29342H10.2319" stroke="#B9B9B9" strokeWidth="2" strokeLinecap="round" stroke-linejoin="round" />
            <path d="M16.3306 9H5.20312" stroke="#B9B9B9" strokeWidth="2" stroke-miterlimit="10" strokeLinecap="round" />
            <path d="M12.4062 13.2787L16.0762 9.60875C16.2371 9.44645 16.3273 9.22722 16.3273 8.99875C16.3273 8.77028 16.2371 8.55105 16.0762 8.38875L12.4062 4.71875" stroke="#B9B9B9" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>

    );
}

export default LogoutIcon;
