import Sidebar from './Sidebar';
import { Outlet } from 'react-router-dom';

const MasterLayout = () => {
    return (
        <div className="vertical-layout boxed h-100" >
            <div className="d-block h-100 page-box ">
                <div className="d-flex flex-column h-100 app-container">
                    {/* <Navbar /> */}

                    <Sidebar />

                    <main className="main-content h-100">
                        <div className="main-content-wrap h-100">
                            <Outlet />
                        </div>
                    </main>
                </div>
            </div>
        </div>
    );
}

export default MasterLayout;
