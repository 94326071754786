import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import agent from "../../../app/api/agent";
import { useMutation } from "react-query";

import { AuthModel } from "../core/_models";
import { useAuth } from "../core/Auth";

import SubmitButton from "../../components/form/SubmitButton";
import { Response } from "../../helper/crud-helpers/models";

const Login = () => {
  const { saveAuth, setCurrentUser } = useAuth();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "onChange" });

  const { mutate, isLoading, isError } = useMutation(
    async (newObj) => {
      const response = await agent.Account.login(newObj);
      return response;
    },
    {
      onSuccess: async (response) => {
        const dataFromResponse = response.data;

        var auth: AuthModel = { api_token: dataFromResponse?.token || "" };
        saveAuth(auth);
        //  const user = await getUserByToken(auth.api_token);
        setCurrentUser(dataFromResponse?.user);
      },
      onError(error: any) {
        toast.error(error.data.message);
      },
    }
  );
  const onSubmit = async (data: any) => {
    try {
      const { email, password } = data;
      const isTokenExist = localStorage.getItem("token");
      if (isTokenExist) localStorage.removeItem("token");
      //  const response = await agent.Account.login({ username: email, password });
      // @ts-ignore
      await mutate({ username: email, password });
    } catch (error) {
      toast.error("Authentication Failed");
    }
  };

  return (
    <div className="col-lg-6 col-sm-12">
      <div className="content-box">
        <div className="content-body">
          <div className="w-100">
            <div className="form-login">
              <h5 className="text-center">Sign in to Tazmeen Ai</h5>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="form-group input-filed">
                  <label>Email</label>
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Email"
                    {...register("email", {
                      required: "Email is required",
                    })}
                  />
                  {errors.email && (
                    <span className="error text-danger mt-1">
                      Email Is Required
                    </span>
                  )}
                </div>

                <div className="form-group input-filed">
                  <label>Password</label>
                  <input
                    className="form-control"
                    type="password"
                    placeholder="Password"
                    {...register("password", {
                      required: "Password is required",
                    })}
                  />
                  {errors.password && (
                    <span className="error text-danger mt-1">
                      password Is Required
                    </span>
                  )}
                </div>
                <div className="d-flex justify-content-between">
                  <div className="form-check form-switch mb-4">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="remember-me"
                    />
                    <label className="form-check-label" htmlFor="remember-me">
                      Remember me
                    </label>
                  </div>
                  <p className="mt-2 ">
                    <a href="test">Forgot password?</a>
                  </p>
                </div>
                <div className="actions justify-content-between">
                  <SubmitButton isLoading={isLoading} title={"Sign in"} />
                </div>
              </form>
              <p className="mt-5 mb-1">
                Don't have an account? <a href="./sign-up.html">Sign up!</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
