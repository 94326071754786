import { Outlet } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { Suspense } from "react";
import { ToastContainer } from "react-toastify";
import Spinner from "./app/components/spinner";

import { AuthInit } from './app/auth'
function App() {
    return (
        <Suspense fallback={<Spinner />}>
            <ToastContainer position="top-right" hideProgressBar autoClose={3000} />
            <AuthInit>
                <Outlet />
            </AuthInit>

        </Suspense>
    );
}

export default App;
