import axios, { AxiosResponse } from "axios";

//@ts-ignore
import { CountryModel } from "../models/CountryModel";
// import { EpisodeDetailsModel } from "../models/EpisodeDetailsModel";
//@ts-ignore
import { CityModel } from "../models/CityModel";
import { ListModel, Response } from "../helper/crud-helpers/models";
//@ts-ignore
import { CompanyModel } from "../models/CompanyModel";
import {
  CategoryModel,
  ClientModel,
  PriorityModel,
  ProjectListModel,
} from "../../features/project/core/models";
import { EpisodeListModel } from "../models/EpisodeListModel";
import { requests } from "../auth/core/AuthHelpers";
import { CharacterModel } from "../../features/episode/lines/core/models";
import { ServiceModel } from "../../features/episode/details/core/model";
import { AuthModel, UserModel } from "../auth";

interface File {
  type: "file";
  name: string;
  path: string;
}

interface Folder {
  type: "folder";
  name: string;
  children: (Folder | File)[];
}
export interface ApiResponseFolder {
  type: string;
  name: string;
  children: File[];
}
const responseBody = (response: AxiosResponse) => response.data;

const Account = {
  login: (values: any): Promise<Response<{ user: UserModel; token: string }>> =>
    requests.post("/account/api/auth/login", values),
  checkToken: () => requests.get("check-auth"),
  register: (values: any) => requests.post("account/register", values),
  currentUser: () => requests.get("account/currentUser"),
  fetchAddress: () => requests.get("account/savedAddress"),
};

const Company = {
  getCompany: (): Promise<Response<{ Company: CompanyModel[] }>> =>
    requests.get("/tazmeen_clients/company/"),
  getCompanyById: (
    companyId: string | undefined
  ): Promise<Response<{ Company: CompanyModel }>> =>
    requests.get(`/tazmeen_clients/company/${companyId}/`),
  getCountry: (): Promise<Response<{ Country: CountryModel[] }>> =>
    requests.get("helper/country/"),
  getCity: (countryId: string): Promise<Response<{ City: CityModel[] }>> =>
    requests.get(`helper/city/get_city_by_country/${countryId}/`),
  addCompany: (values: any) =>
    requests.post("tazmeen_clients/company/", values),
};

const Employee = {
  addEmployee: (values: any): Promise<Response<{ Employee: any }>> =>
    requests.post("tazmeen_clients/employee/", values),
};
const Project = {
  // getProjects: (): Promise<Response<{ Project: ProjectListModel[] }>> => requests.get("/tazmeen_clients/project/"),
  //  getProjects: () => requests.get("tazmeen_clients/project/"),
  getProjectById: (projectId: string) =>
    requests.get(`tazmeen_clients/project/${projectId}/`),
  getProjectPriority: (): Promise<
    Response<{ ProjectPriority: PriorityModel[] }>
  > => requests.get("tazmeen_clients/project_priority/"),
  //getServices: () => requests.get("tazmeen_services/service/"),
  getServices: (): Promise<Response<{ Service: ServiceModel[] }>> =>
    requests.get("tazmeen_services/service/"),
  getCategory: (): Promise<Response<{ ProjectCategory: CategoryModel[] }>> =>
    requests.get("tazmeen_clients/project_category/"),
  getLanguage: () => requests.get("helper/sub_language/"),
  getClient: (): Promise<Response<{ CompanyClient: ClientModel[] }>> =>
    requests.get("tazmeen_clients/company_client/"),
  createClient: (
    payload: any
  ): Promise<Response<{ CompanyClient: ClientModel }>> =>
    requests.post("/tazmeen_clients/company_client/", {
      ...payload,
    }),
  // addProject: (values: any) =>
  //   requests.post("tazmeen_clients/project/", values),

  getProjectRootVideoFolder: (
    projectId: string
  ): Promise<Response<ApiResponseFolder>> =>
    requests.get(
      `tazmeen_clients/project/${projectId}/get_root_folder_by_project_id/V/`
    ),
  getProjectRootSrtFolder: (
    projectId: string
  ): Promise<Response<ApiResponseFolder>> =>
    requests.get(
      `tazmeen_clients/project/${projectId}/get_root_folder_by_project_id/S/`
    ),
  getProjectRootTazmeenExcelFolder: (
    projectId: string
  ): Promise<Response<ApiResponseFolder>> =>
    requests.get(
      `tazmeen_clients/project/${projectId}/get_root_folder_by_project_id/TX/`
    ),
  getProjectRootTazmeenAudioFolder: (
    projectId: string
  ): Promise<Response<ApiResponseFolder>> =>
    requests.get(
      `tazmeen_clients/project/${projectId}/get_root_folder_by_project_id/TA/`
    ),
};

const Episode = {
  // getEpisodesForPorject: (projectId: string): Promise<Response<{ Episode: EpisodeListModel[] }>> =>
  //   requests.get(
  //     `tazmeen_clients/episode/get_episode_by_project/${projectId}/`
  //   ),
  // getEpsiodeDetails: (epsiodeId: string): Promise<Response<{ Episode: EpisodeDetailsModel }>> =>
  //   requests.get(`tazmeen_clients/episode/${epsiodeId}/`),
  addEpisode: (
    values: any
  ): Promise<Response<{ Episode: EpisodeListModel }>> => {
    // const formData = new FormData();
    return axios
      .post(
        "tazmeen_clients/episode/",
        { ...values },
        {
          // headers: {
          //   "Content-Type": "multipart/form-data",
          // },
        }
      )
      .then(responseBody);
    // for (const key in values) {
    //   if (key === "v_a_file" || key === "s_file") {
    //     formData.append(key, values[key][0]);
    //   } else if (key === "service" && Array.isArray(values[key])) {
    //     values[key].forEach((serviceItem: any) => {
    //       formData.append(key, serviceItem);
    //     });
    //   } else {
    //     formData.append(key, values[key]);
    //   }
    // }

    // return axios
    //   .post("tazmeen_clients/episode/", formData, {
    //     headers: {
    //       "Content-Type": "multipart/form-data",
    //     },
    //   })
    //   .then(responseBody);
  },
  updateEpisode: (values: any, episodeId: string) => {
    const updateData = new FormData();
    for (const key in values) {
      if (key === "v_a_file" || key === "s_file") {
        // console.log(values[key]);
        updateData.append(key, values[key]);
      } else if (key === "service" && Array.isArray(values[key])) {
        values[key].forEach((serviceItem: any) => {
          updateData.append(key, serviceItem.value);
        });
      } else {
        updateData.append(key, values[key]);
      }
    }

    return axios
      .patch(`tazmeen_clients/episode/${episodeId}/`, updateData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(responseBody);
  },
  // startEpisodeProcessing: (episodeId: string) => {
  //   return axios
  //     .put(`tazmeen_clients/episode/${episodeId}/start_processing_episode/`)
  //     .then(responseBody);
  // },
  // cancelEpisodeProcessing: (episodeId: string) => {
  //   return axios
  //     .put(`tazmeen_clients/episode/${episodeId}/cancel_episode_processing/`)
  //     .then(responseBody);
  // },
  // pauseEpisodeProcessing: (episodeId: string) => {
  //   return axios
  //     .put(`tazmeen_clients/episode/${episodeId}/pause_episode_processing/`)
  //     .then(responseBody);
  // },
  // resumeEpisodeProcessing: (episodeId: string) => {
  //   return axios
  //     .put(`tazmeen_clients/episode/${episodeId}/un_pause_episode_processing/`)
  //     .then(responseBody);
  // },

  // getEpisodeLines: (episodeId: string): Promise<Response<{ Line: LineModel[] }>> => {
  //   return axios
  //     .get(`tazmeen_clients/line/${episodeId}/get_episode_lines/`)
  //     .then(responseBody);
  // },

  getCharacters: (episodeId: string) => {
    return axios
      .get(`tazmeen_clients/character/${episodeId}/get_project_character/`)
      .then(responseBody);
  },

  getDubbingActor: (): Promise<
    Response<{ DubbingActor: { id: string; name: string }[] }>
  > => {
    return axios.get(`tazmeen_services/dubbing_actor/`).then(responseBody);
  },
  updateProjectCharacter: (
    project_id: string,
    payload: any
  ): Promise<Response<{ character: CharacterModel }>> =>
    axios.put(
      `tazmeen_clients/character/${project_id}/update_project_characters/`,
      { character: payload }
    ),

  clearEpisodeLine: (episodeId: string) => {
    return axios
      .put(`tazmeen_clients/episode/${episodeId}/clear_episode_lines/`)
      .then(responseBody);
  },
  getVideoPeaks: (episodeId: string): Promise<Response<number[]>> => {
    return axios
      .get(`tazmeen_clients/episode/${episodeId}/video/`)
      .then(responseBody);
  },
  approveCurrentInsertNextStep: (episodeId: string) => {
    return axios
      .put(
        `tazmeen_clients/episode/${episodeId}/approve_current_insert_next_step/`
      )
      .then(responseBody);
  },
  getCharacter: (episodeId: string) => {
    return axios
      .get(`tazmeen_clients/character/${episodeId}/get_project_character/`)
      .then(responseBody);
  },

  addLine: (body: any) => {
    return requests
      .post(`/tazmeen_clients/line/`, {
        ...body,
      })
      .then(responseBody);
  },
  deleteLine: (episodeId: string) => {
    return requests
      .delete(`/tazmeen_clients/line/${episodeId}/`)
      .then(responseBody);
  },
  updateLine: (episodeId: string, body: any) => {
    return requests
      .put(`/tazmeen_clients/line/${episodeId}/update_episode_lines/`, {
        ...body,
      })
      .then(responseBody);
  },
};

const agent = {
  Account,
  Company,
  Employee,
  Project,
  Episode,
};
export default agent;
