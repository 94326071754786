import clsx from "clsx";
import styles from "./sidebar.module.css";
import { ChangeEventHandler, FC } from "react";

type Props = {
  onChange: ChangeEventHandler<HTMLInputElement>; // Define the correct type for onChange
};
export const InputSearch: FC<Props> = ({ onChange }) => {
  return (
    <>
      <div className={styles["search-container"]}>
        <input
          style={{ borderRadius: "8px", padding: "5px 25px" }}
          type="text"
          className={clsx("form-control")}
          placeholder="Search..."
          onChange={onChange}
        />
        <i className={clsx("fa", "fa-search", styles["search-icon"])}></i>
      </div>
    </>
  );
};
