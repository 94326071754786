import { FC } from "react";
interface Props {
  isLoading: boolean;
  title?: string;
  disabled?: boolean;
}
const SubmitButton: FC<Props> = ({ isLoading, title, disabled }) => {
  return (
    <div className="d-grid mb-10">
      <button
        type="submit"
        id="kt_sign_in_submit"
        className="btn btn-primary ml-auto"
        disabled={isLoading || disabled}
      >
        {!isLoading && (
          <span className="indicator-label">{title ? title : "Submit"}</span>
        )}
        {isLoading && (
          <span className="indicator-progress" style={{ display: "block" }}>
            Please wait...
            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
          </span>
        )}
      </button>
    </div>
  );
};

export default SubmitButton;
