import axios, { AxiosResponse } from "axios";
import { UserModel } from "./_models";
import { Response } from "../../helper/crud-helpers/models";

export type UsersQueryResponse = Response<any>
export async function getUserByToken(token: string): Promise<UsersQueryResponse> {
    return axios.get('account/api/auth/validate_token/').then((d: AxiosResponse<UsersQueryResponse>) => d.data);


}

