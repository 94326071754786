import axios, { AxiosError, AxiosResponse } from "axios";
import { AuthModel } from "./_models";
import { toast } from "react-toastify";

const AUTH_LOCAL_STORAGE_KEY = "kt-auth-react-v";
//const API_URL = process.env.REACT_APP_API_URL;
const getAuth = (): AuthModel | undefined => {
  if (!localStorage) {
    return;
  }

  const lsValue: string | null = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY);
  if (!lsValue) {
    return;
  }

  try {
    const auth: AuthModel = JSON.parse(lsValue) as AuthModel;
    if (auth) {
      // You can easily check auth_token expiration also
      return auth;
    }
  } catch (error) {
    console.error("AUTH LOCAL STORAGE PARSE ERROR", error);
  }
};

const setAuth = (auth: AuthModel) => {
  if (!localStorage) {
    return;
  }

  try {
    const lsValue = JSON.stringify(auth);
    localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, lsValue);
  } catch (error) {
    console.error("AUTH LOCAL STORAGE SAVE ERROR", error);
  }
};

const removeAuth = () => {
  if (!localStorage) {
    return;
  }

  try {
    localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY);
  } catch (error) {
    console.error("AUTH LOCAL STORAGE REMOVE ERROR", error);
  }
};
const responseBody = (response: AxiosResponse) => response.data;
export const requests = {
  get: (url: string, params?: URLSearchParams) =>
    axios.get(url, { params: params }).then(responseBody),
  post: (url: string, body: {}) => axios.post(url, body).then(responseBody),
  put: (url: string, body: {}) => axios.put(url, body).then(responseBody),
  delete: (url: string) => axios.delete(url).then(responseBody),
};

export function setupAxios(axios: any) {
  const currentDomain = window.location.hostname;

  const testBaseUrl = process.env.REACT_APP_TEST_API_URL;
  const liveBaseUrl = process.env.REACT_APP_LIVE_API_URL;
  let baseUrl = testBaseUrl;
  if (currentDomain === liveBaseUrl) {
    baseUrl = liveBaseUrl;
  }

  axios.defaults.baseURL = `https://${baseUrl}/api/`; // "https://tazmeen.ai/api/"; //baseUrl;//

  const responseBody = (response: AxiosResponse) => response.data;

  axios.interceptors.request.use((config: any) => {
    const auth = getAuth();
    if (auth && auth.api_token) {
      config.headers.Authorization = `token ${auth.api_token}`;
    }
    //  const token = localStorage.getItem("token");

    // if (token) config.headers.Authorization = `token ${token}`;
    return config;
  });
  const responseInterceptor = (response: AxiosResponse) => {
    return response;
  };
  axios.interceptors.response.use(responseInterceptor, (error: AxiosError) => {
    const { data, status } = error.response as any;
    if (error.response) {
      switch (error.response?.status) {
        case 400:
          toast.error(JSON.stringify(error.response?.data.message));
          break;
        case 401:
          // toast.error(JSON.stringify(data.message), {
          //   style: { background: "white", color: "red" },
          // });
          break;
        case 403:
          toast.error("Forbidden", {
            style: { background: "red", color: "white" },
          });
          break;
        case 404:
          toast.error("Not Found", {
            style: { background: "red", color: "white" },
          });
          break;
        case 405:
          toast.error("Not Allowed", {
            style: { background: "red", color: "white" },
          });
          break;
        case 500:
          toast.error(data?.message, {
            style: { background: "red", color: "white" },
          });
          //history.push({ pathname: "/server-error", state: { error: data } });
          break;
      }
    }
    return Promise.reject(error.response);
  });
}

export { getAuth, setAuth, removeAuth, AUTH_LOCAL_STORAGE_KEY };
