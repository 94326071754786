import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../auth";
import ControlPanelICon from "../components/icons/ControlPanel";
import logo from "../../assets/img/logo.svg";
import userImg from "../../assets/img/user.png";
import LogoutIcon from "../components/icons/LogoutIcon";
// import { useUserContext } from "../context/UserContext";
import styles from "./sidebar.module.css";
import clsx from "clsx";
import { InputSearch } from "./InputSearch";
import { useCallback, useState } from "react";
const Sidebar = () => {
  const { currentUser, logout } = useAuth();
  const [searchString, setSearchString] = useState<string>("");

  const handelOnclick = () => {
    localStorage.removeItem("episodeData");
    logout();
  };
  type RoutePath = {
    title: string;
    path: string;
    icon: React.ReactNode;
    permission?: boolean;
    order: number;
  };
  const routesPath: RoutePath[] = [
    {
      title: "Dashboard",
      path: "notfound",
      icon: <i className="fa fa-th-large  link-icon " aria-hidden="true"></i>,
      order: 1,
    },
    {
      title: "Companies",
      path: "/companies/list",
      icon: <i className="fa fa-star  link-icon " aria-hidden="true"></i>,
      permission: currentUser?.type === "TzAd",
      order: 2,
    },
    {
      title: "Projects",
      path: "/projects-category/",
      icon: <i className="fa fa-tasks  link-icon " aria-hidden="true"></i>,
      permission: currentUser?.type === "ClAd",
      order: 3,
    },

    {
      title: "User",
      path: "notfound",
      icon: <i className="fa fa-user-o  link-icon " aria-hidden="true"></i>,
      order: 4,
    },
    {
      title: "Control Panel",
      path: "/control-panel",
      icon: <ControlPanelICon />,
      permission: currentUser?.type === "TzAd",
      order: 5,
    },
    {
      title: "Setting",
      path: "notfound",
      icon: <i className="fa fa-cog  link-icon " aria-hidden="true"></i>,
      order: 6,
    },
  ];

  const handleInputChange: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    setSearchString(event.target.value); // Update the state with the new input string
  };

  const filteredRoutes = routesPath.filter((route) =>
    route.title.toLowerCase().includes(searchString.toLowerCase())
  );
  return (
    <>
      <div id="navbar2" className="app-navbar vertical">
        <div
          className="d-flex flex-column align-items-start  justify-content-between navbar-wrap w-100 "
          style={{ padding: "2.14286rem 1.07143rem " }}
        >
          {/* <button className="no-style navbar-toggle navbar-close icofont-close-line d-lg-none"></button> */}
          <div className="d-flex flex-column align-items-center gap-4">
            <div className={styles["logo-wrap"]}>
              <img src={logo} alt="logo" />
            </div>

            <div
              className="d-flex justify-content-around align-items-center gap-1"
              style={{ padding: "0 1.07143rem " }}
            >
              <img
                src={userImg}
                alt=""
                width="30"
                height="30"
                className="rounded-500 me-1"
              />
              <span>Nabeel Dakkkak</span>
            </div>
            <InputSearch onChange={handleInputChange} />
            <div className="main-menu ">
              <nav className="main-menu-wrap px-4">
                <ul className="menu-ul p-0">
                  {filteredRoutes.map((item) => {
                    if (item.permission == undefined || item.permission)
                      return (
                        <li className="menu-item p-0" key={item.order}>
                          <Link to={item.path} className="item-link">
                            {item.icon}
                            <span className="link-text">{item.title}</span>
                          </Link>
                        </li>
                      );
                  })}
                </ul>
              </nav>
            </div>
          </div>

          <div className="main-menu px-4 py-0">
            <nav className="main-menu-wrap p-0">
              <ul className="menu-ul p-0">
                <li className="menu-item p-0">
                  <Link onClick={handelOnclick} className="item-link" to={""}>
                    <LogoutIcon />
                    <span className="link-text"> Logout</span>
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
